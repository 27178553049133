// polyfill.js
'use strict';
import URLSearchParams from '@ungap/url-search-params';
import smoothscroll from 'smoothscroll-polyfill';
import '../resource/intersectionObserver';
import AudioRecorder from 'audio-recorder-polyfill';
import '../resource/canvasToBlob.js';
import ResizeObserver from 'resize-observer-polyfill';

if (!window.URLSearchParams) {
  window.URLSearchParams = URLSearchParams;
}

if (window) {
  smoothscroll.polyfill();
}

if (window) {
  window.MediaRecorder = AudioRecorder;
}

if (!window.requestIdleCallback) {
  window.requestIdleCallback = window.setTimeout;
}

if (!window.cancelIdleCallback) {
  window.cancelIdleCallback = function (id) {
    window.clearTimeout(id);
  };
}

if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver;
}
