// getJsEnvironment.js
'use strict';

export const getIsInBrowser = () =>
  typeof window !== 'undefined' &&
  typeof self !== 'undefined' &&
  window === self;

export const getIsInNode = () =>
  typeof process !== 'undefined' && process.release?.name === 'node';

export const getIsInServiceWorker = () =>
  typeof self !== 'undefined' &&
  typeof self.ServiceWorkerGlobalScope !== 'undefined';

export const getIsInIframe = () => {
  if (getIsInNode() || getIsInServiceWorker()) return false;

  // refer to: https://stackoverflow.com/questions/326069/how-to-identify-if-a-webpage-is-being-loaded-inside-an-iframe-or-directly-into-t
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const getIsInNext = () => {
  return getIsInBrowser() && typeof window.next !== 'undefined';
};

export const getIsInNextMiddleware = () => {
  return typeof process !== 'undefined' && process.env.NEXT_RUNTIME === 'edge';
};
