// env.js
'use strict';
import { getIsInNext } from '../resource/getJsEnvironment.js';

const isServer = typeof window === 'undefined';
const isNext = getIsInNext();

const env = Object.assign(
  {},
  process.env.DOTENV_PARSED_ENV,
  process.env,
  isServer ? undefined : window.__SSR_ENVIRONMENT__,
  {
    BRANCH_NAME: process.env.BRANCH_NAME,
    TAG_NAME: process.env.TAG_NAME,
    SHORT_SHA: process.env.SHORT_SHA,
  }
);

// replace values with SSR remote config
if (!isServer) {
  Object.keys(env).forEach(key => {
    const rcValue = window.__PRELOADED_STATE__?.remoteConfig?.[key];
    if (undefined !== rcValue) {
      env[key] = rcValue;
    }
  });

  // keys should never been remote configured
  env.BRANCH_NAME = process.env.BRANCH_NAME;
  env.TAG_NAME = process.env.TAG_NAME;
  env.SHORT_SHA = process.env.SHORT_SHA;

  if (isNext) {
    env.NODE_ENV = process.env.NODE_ENV;
  }
}

export const updateEnv = newConfig => {
  try {
    Object.keys(newConfig).forEach(key => {
      env[key] = newConfig[key];
    });
    // eslint-disable-next-line no-empty
  } catch (_) {}
};

export default env;
