// debug.js
'use strict';
import Debug from 'debug';

import env from '../resource/env.js';

const severity = {
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  INFO: 'INFO',
  DEBUG: 'DEBUG',
};

const severityMap = {
  error: severity.ERROR,
  warning: severity.WARNING,
  log: severity.INFO,
  info: severity.INFO,
  debug: severity.DEBUG,
};

// safely handles circular references
// ref: https://stackoverflow.com/a/11616993
JSON.safeStringify = (object, indent = 2) => {
  let cache = [];
  const result = JSON.stringify(
    object,
    (key, value) => {
      let result = value;
      if (typeof value === 'object' && value !== null) {
        if (cache.includes(value)) {
          result = '[Duplicate]';
        } else {
          cache.push(value); // Store value in our collection
          if (Object.keys(value).length > 128) {
            result = `[Object keys: ${Object.keys(value).length}]`;
          }
        }
      }
      return result;
    },
    indent
  );
  cache = null;
  return result;
};

const isServer = typeof window === 'undefined';
if (isServer && env.DEBUG_FORMAT === 'json') {
  Debug.log = function (...args) {
    const [isoTime, namespace, ...textArray] = args[0]?.split(' ') || [];
    const labels = namespace?.split(':') || [];
    const data = args[1];
    const [, requestId] =
      labels[labels.length - 1].match(/^request_id-(.*)$/) || [];

    const output = {
      severity: severityMap[labels[1]] || severity.DEBUG,
      message: textArray?.join(' '),
      labels: {
        namespace,
        topNamespace: labels[0],
        functionName: labels[2],
        version: env.TAG_NAME || env.BRANCH_NAME || 'local',
        sha: env.SHORT_SHA,
      },
      timestamp: isoTime,
      ms: this.diff,
    };
    if (data) {
      output.data = data;
    }
    if (requestId) {
      output.labels.requestId = requestId;
    }
    // use .safeStringify() to prevent 'circular' JSON, ex: 'request' object from express
    // eslint-disable-next-line no-console
    console.log(JSON.safeStringify(output, null));
  };
}

globalThis.enableDebug = debugString => {
  const debugs = debugString.split(',');
  debugs.forEach(debug => Debug.enable(debug));
};

/**
 * Disable debug
 */
export const disableDebug = () => {
  return Debug.disable();
};

/**
 * Enable debug
 * @param {string} {namespace} - debug namespace
 */
export const enableDebug = ({ namespace }) => {
  return Debug.enable(namespace);
};

export const init = Debug('init');
export const redux = Debug('redux');
export const persist = Debug('persist');
export const player = Debug('player');
export const payment = Debug('payment');
export const pusher = Debug('pusher');
export const pushy = Debug('pushy');
export const mixpanel = Debug('mixpanel');
export const pubsub = Debug('pubsub');
export const gtm = Debug('gtm');
export const actionError = Debug('action:error');
export const actionLog = Debug('action:log');
export const selectorError = Debug('selector:error');
export const componentError = Debug('component:error');
export const serviceWorkerLog = Debug('serviceWorker:log');
export const resourceLog = Debug('resource:log');
export const legacySSR = Debug('legacy-ssr');
export const redis = Debug('redis');
export const fetchCache = Debug('fetch-cache');
export const upload = Debug('upload');
export const game = Debug('game');
export const nextAppLog = Debug('next-app:log');
export const nextAppError = Debug('next-app:error');
export const pushNotification = Debug('pushNotification');
export const turnstile = Debug('turnstile');
export const customFetch = Debug('customFetch');
export const queue = Debug('queue');
export const buildHtml = Debug('build-html');
export const sitemapHandler = Debug('sitemap-handler');

export default {
  init,
  actionError,
  actionLog,
  selectorError,
  componentError,
  player,
  payment,
  pusher,
  gtm,
  mixpanel,
  serviceWorkerLog,
  resourceLog,
  legacySSR,
  redis,
  fetchCache,
  upload,
  disableDebug,
  enableDebug,
};
